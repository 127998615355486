<template>
  <div :class="{ 'c-dark-theme': false, animated: false, fadeIn: true }">
    <!-- 유조 리스트 ------------------------------------------------------>

    <CCard border-color="info">
      <CCardHeader>
        <BRow>
          <BCol>
            <BIconArrowsExpand/> 온도별 체적 환산비율
          </BCol>
          <BCol class="text-right">
            <BButton size="sm" variant="primary" class="mb-0" @click="$parent.setGroup(null)">
              <BIconEyeFill/> 환산그룹 전체조회
            </BButton>
          </BCol>
        </BRow>
      </CCardHeader>

      <CCardBody>

        <vue-excel-editor
          v-model="rows"
          ref="excelGrid"
          width="100%"
          class="mb-1"
          :page="numOfRecord"
          :readonly-style="{backgroundColor:'#4F5040'}"
          @update="updateCell"
          @select="selectRecord"
          :localized-label="editorLabel"
          filter-row>
          <vue-excel-column field="_id"                     type="string"   key-field invisible/>
          <vue-excel-column field="fa"                      type="number"   width="80px"  label="화씨(F)" readonly sticky/>
          <vue-excel-column field="cs"                      type="number"   width="80px"  label="섭씨(C)" :to-text="v=>{return v?v.toFixed(1): ''}" readonly/>
          <vue-excel-column field="group1" type="number" width="80px" label="그룹 1" :invisible="hideCols[1]" readonly/>
          <vue-excel-column field="group2" type="number" width="80px" label="그룹 2" :invisible="hideCols[2]" readonly/>
          <vue-excel-column field="group3" type="number" width="80px" label="그룹 3" :invisible="hideCols[3]" readonly/>
          <vue-excel-column field="group4" type="number" width="80px" label="그룹 4" :invisible="hideCols[4]" readonly/>
          <vue-excel-column field="group5" type="number" width="80px" label="그룹 5" :invisible="hideCols[5]" readonly/>
          <vue-excel-column field="group6" type="number" width="80px" label="그룹 6" :invisible="hideCols[6]" readonly/>
          <vue-excel-column field="group7" type="number" width="80px" label="그룹 7" :invisible="hideCols[7]" readonly/>
          <vue-excel-column field="group0" type="number" width="80px" label="그룹 0" :invisible="hideCols[0]" readonly/>
          <vue-excel-column field="updatedAt"               type="date"     width="110px" label='수정일' :to-text="toLocalTime" readonly/>
          <vue-excel-column field="updId"                   type="string"   width="80px" label='수정자' readonly/>
          <vue-excel-column field="createdAt"               type="date"     width="110px" label='등록일' :to-text="toLocalTime" readonly/>
          <vue-excel-column field="regId"                   type="string"   width="80px" label='등록자' readonly/>
        </vue-excel-editor>

      </CCardBody>
    </CCard>



  </div>
</template>



<script>

//-------------------------------------------------------------------------------------------------
import '@/common/HelperMixin';
import {
  apiCall, cloneVar
} from '@/common/utils';
// import { Swatches } from 'vue-color'
import {ExcelEditorLabel} from "@/common/constants";

const _oil = {
  code           : '',
  name           : '신규유종',
  oilTempGroup   : '',
  expansionRate  : '',
  density        : '',
  prdCode        : '',
  color          : '',
  stockpile      : '',
  dayConsumption : '',
};

const _oil_temp = {
  fa          : '',
  cs          : '',
  group1      : '',
  group2      : '',
  group3      : '',
  group4      : '',
  group5      : '',
  group6      : '',
  group7      : '',
  group0      : '',
};


const oilTempColMap = {
  fa          : '화씨',
  cs          : '섭씨',
  group1      : '비중그룹1',
  group2      : '비중그룹2',
  group3      : '비중그룹3',
  group4      : '비중그룹4',
  group5      : '비중그룹5',
  group6      : '비중그룹6',
  group7      : '비중그룹7',
  group0      : '비중그룹0',
};

//----------------------------------------------------------------------------------------------------
export default {
  name: "OilTempList",
  components: {
  },
  props: {
    simple: { type: Boolean, default: false },
    groupIndex: { type: String, default: null},

  },
  data () {
    return {
      hideCols: [false,false,false,false,false,false,false,false,false],
      editorLabel: ExcelEditorLabel,
      numOfRecord: 20,
      selectedRow: null,
      isDeleting: false,
      pickerShow: false,
      oilCodeMap: this.$store.state.codeMaps['OIL_CODE'],
      tankTypeMap: this.$store.state.codeMaps['TANK_TYPE'],
      isCreateMode: false,
      rows: [],
      oilTempOpts:[
        {value:null, label:'체적환산그룹 선택'},
        {value:'1',  label:'그룹 1'},
        {value:'2',  label:'그룹 2'},
        {value:'3',  label:'그룹 3'},
        {value:'4',  label:'그룹 4'},
        {value:'5',  label:'그룹 5'},
        {value:'6',  label:'그룹 6'},
        {value:'7',  label:'그룹 7'},
        {value:'0',  label:'그룹 0'},
      ],
      fields: this.getFields(),
      formShow: false,
      isSubmitting: false,
      row: null,
    }

  },
  async created(){
    try{
      this.setColumnShow();
      await this.getList();

    }catch(err){
      console.log(err);
    }
  },

  computed: {},

  mounted() {
    console.log("--- MachineForm mounted---------------------");
    // below is not work!
  },

  methods: {
    setColumnShow(){
      if(this.groupIndex){
        let idx = Number(this.groupIndex);
        for(let i=0; i<this.hideCols.length; i++){
          if(idx!==i) this.hideCols[i] = true;
        }
      }else{
        for(let i=0; i<this.hideCols.length; i++){
          this.hideCols[i] = false;
        }
      }
    },
    async createRow(){
      try{
        this.isCreateMode = true;
        await this.$refs.table.clearSelected();
        this.row = cloneVar(_oil);
      }catch(err){
        console.log(err);
        this.isCreateMode = false;
      }

    },
    async selectRecord(idx, evt){
      if(!evt){
        // this.tankFormShow = false;
        this.selectedRow = null;
        return;
      }

      this.selectedRow = this.$refs['excelGrid'].getSelectedRecords().pop();

      console.log( 'select idx, tank --->', idx, this.selectedRow );

    },

    async getList(){
      try{
        console.log( "getOilList() --- query-string ----> ");
        this.rows = [];
        const r = await apiCall('get', `/api/oil/temp`);
        if(r.code===200){
          this.rows = r.result;
          await this.toastInfo(`체적환산 그룹 ${this.rows.length}건 조회됨`, 'info');

        }
        this.selectedTank = null;

      }catch(err){
        await this.alertDanger(err.message);
        console.log( err );
      }
    },

    rowSelected(item){
      console.log("------------ screenRowSelected ------------", item.length);
      if( item.length === 0 ) {
        this.row = null;
      }else{
        this.row = item.pop();
      }
    },
    getFields(){
      if(!this.simple) {
      return [
        { key: 'code'          ,  sortable: true, tdClass: 'text-center font-weight-bold', label:'유종코드'},
        { key: 'name'          ,  sortable: true, label:'유종명'},
        { key: 'color'         ,  sortable: true, label:'색상'},
        { key: 'oilTempGroup'  ,  sortable: true, label:'비중그룹' },
        { key: 'expansionRate' ,  sortable: true, label:'팽창계수'},
        { key: 'density'       ,  sortable: true, label:'기준밀도'},
        { key: 'prdCode'       ,  sortable: true, label:'상품코드'},
        { key: 'stockpile'     ,  sortable: true, label:'비축량'},
        { key: 'dayConsumption',  sortable: true, label:'1일소비량'},
        { key: 'updId'         ,  sortable: true, label:'수정자'},
        { key: 'updatedAt'     ,  sortable: true, label:'수정일', formatter: (v)=>{ return this.toLocalTime(v) }},
        { key: 'regId'         ,  sortable: true, label:'등록자'},
        { key: 'createdAt'     ,  sortable: true, label:'등록일', formatter: (v)=>{ return this.toLocalTime(v) }},
      ];
      }else{
        return [
          { key: 'code'          ,  sortable: true, tdClass: 'text-center font-weight-bold', label:'유종코드'},
          { key: 'name'          ,  sortable: true, label:'유종명'},
          { key: 'oilTempGroup'  ,  sortable: true, label:'비중그룹' },
          { key: 'color'         , _style: 'min-width:5%', label:'색상'},
        ];
      }
    },
    async formSubmit(evt){
      let r;
      evt.preventDefault();
      try{
        this.isSubmitting = true;
        if(this.row._id) {
          // 정보 수정
          r = await apiCall("PUT", `/api/oil/${this.row._id}`, this.row);
        }else{
          // 정보 등록
          let checkCode = true;
          this.rows.map(r=>{
            if( r.code===this.row.code ) checkCode = false;
          });
          if(!checkCode) {
            await this.alertDanger('동일코드가 있습니다.', 'E110');
            this.isCreateMode = false;
            return;
          }else{
            r = await apiCall("POST", `/api/oil`, this.row);
          }

        }
        console.log( r );
        if(r.code===200){
          await this.alertSuccess('유종 정보가 저장 되었습니다.', '유종 정보');
          await this.getList();
        }else{
          await this.toastResult( r, '유종 정보 저장')
        }

      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }finally{
        this.isSubmitting = false;
        this.isCreateMode = false;
      }
    },
    async deleteRow(){
      let r;
      try{
        if(!this.row._id) return;
        if( !(await this.confirmModal('유종정보를 삭제 합니다. 삭제 하시겠습니까?', '유종 삭제')) ){
          return;
        }

        r = await apiCall("DEL", `/api/oil/${this.row._id}`, this.row);
        if(r.code===200){
          await this.alertSuccess('유종 정보가 삭제 되었습니다.', '유종 정보');
          await this.getList();
        }else{
          await this.toastResult( r, '유종 정보 삭제')
        }
      }catch(err){
        console.log(err);
      }
    },
    cancelCreate(){
      this.row = null;
      this.isCreateMode = false;
    },
    async updateCell(rec){

      let rc = rec.pop();
      if(!rc.keys[0]){
        alert( "KEY_NOT_FOUND" );
        return;
      }

      console.log( "updateRecord--------- record.$id--->",rc.$id );
      console.log( "updateRecord--------- record.keys[0]--->",rc.keys[0] );
      // console.log( "updateRecord--------- rec[0]|rc --->",rc );
      // const tIndex = this.$refs['excelGrid'].rowIndex[rc.$id];
      // console.log( "updateRecord--------- rowIndex--->",tIndex );
      // let row = this.$refs['excelGrid'].table[tIndex];
      //
      // console.log( "row ---------> ", row );

      let r = null;

      let param = {};
      const objectId = rc.keys[0];
      const fieldName = rc.name;
      param[fieldName] = rc.newVal;
      try{
        // const row = this.$refs['excelGrid'].currentRecord;
        console.log( 'param --->', param);
        r = await apiCall("PUT", `/api/oil/temp/${objectId}`, param);
        console.log( r );
        await this.toastResult(r, `${oilTempColMap[fieldName]} 수정`);
      }catch(err){
        await this.alertDanger(err.message, r.code, 'danger');
        console.log( err );
      }

    },

    async deleteRecord(recs){
      // let r = null, msg = null, notice = null;
      this.isDeleting = true;
      try{
        let params = this.$refs['excelGrid'].getSelectedRecords();
        this.progCount = 0;
        this.totalCount = params.length;
        if(!params.length) return this.toastInfo('[선텍된 레코드 없음] 좌측의 번호를 클릭하여 레코드 선택');

        console.log( "deleteRecord --- recs ---", recs.length );
        console.log( "deleteRecord --- params ", params );
        console.log( "deleteRecord length ----> ", params.length );

        const confirmMsg = `${params.length} 개의 데이터를 삭제 합니다. 삭제된 데이터는 복구할 수 없으며 시스템 장애가 발생할 수 있습니다. 진행 하시겠습니까?`;

        if( !(await this.confirmModal(confirmMsg, '레코드 삭제')) ){
          return;
        }

        let failCnt = 0;
        for( let record of params){
          console.log( "deleteRecord for --------->", record );
          const tIndex = this.$refs['excelGrid'].rowIndex[record.$id];
          const r = await apiCall('DEL', `/api/oil/temp/${record._id}`);
          console.log( r );
          if( r.code===200 ){
            this.progCount++;
            this.$refs["excelGrid"].deleteRecord(tIndex);
          }else{
            failCnt++;
            this.toastInfo('삭제 에러: '+r.message, 'Error', 'danger' );
          }
        }

        await this.alertModal(`삭제: ${this.progCount}건, 실패: ${failCnt}`, '레코드 삭제결과', 'warning');
        this.$refs['excelGrid'].clearAllSelected();
        await this.getGroupList();


      }catch(err){
        console.log( err );
      }finally{
        this.progCount = 0;
        this.isDeleting = false;
        this.selectedGroup = null;
      }
    },

  } // end of methods
}
</script>
